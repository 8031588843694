import React from "react";
import { Card, Button, Row, Col, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import letterHeader from '../assets/images/letter-header.svg';
const { Title, Text, Paragraph } = Typography;

export default function LetterReview({ data, onPrevious, onSend }) {
    return (
        <Row justify="center" align="middle" style={{ flex: 1 }}>
            <Col xs={24} sm={22} md={20} lg={18} xl={16}>
                <Card bordered={false} className="criclebox h-full" style={{ padding: "32px" }}>
                <ArrowLeftOutlined onClick={onPrevious} style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer', marginRight: '16px', marginBottom: '16px' }} />
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                      
                        <Title level={3} style={{
                            fontFamily: "HvDtrail-Brandon",
                            fontSize: "30.74px",
                            fontWeight: 700,
                            lineHeight: "43.95px",
                            textAlign: "left",
                            marginBottom: 0,
                            color: "#6B4F4F"
                        }}>
                            Review Letter
                        </Title>
                    </div>

                   

                    <Card
                        bordered={false}
                        style={{
                            borderRadius: '20px',
                            padding: '32px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
                            marginBottom: '24px',
                            height: 'calc(100vh - 250px)',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'auto',
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}
                    >
                        {/* Arabic/Urdu Header - Centered */}
                        <div
                            dir="rtl"
                            lang="ur"
                            style={{
                                fontFamily: 'AmiriRegular',
                                fontSize: 'clamp(14px, 2vw, 18px)',
                                textAlign: 'center',
                                color: '#96A1BD',
                                lineHeight: '2.2',
                                marginBottom: '16px',
                            }}
                        >
                                  <div style={{
                            textAlign: 'center',
                            marginBottom: '16px'
                        }}>
                            <img 
                                src={letterHeader}
                                alt="Letter Header"
                                style={{
                                    width: 'clamp(400px, 80%, 600px)',
                                    height: 'auto'
                                }}
                            />
                        </div>
                       
                            {/* Greeting */}
                            {data.language === 'urdu' ? (
                                <div
                                    dir="rtl"
                                    lang="ur"
                                    style={{
                                        fontFamily: 'Noto Nastaliq Urdu',
                                        fontSize: 'clamp(14px, 2.5vw, 18px)',
                                        textAlign: 'right',
                                        color: '#96A1BD',
                                        lineHeight: '2'
                                    }}
                                >
                                    پیارے حضور! <br />
                                    السلام علیکم و رحمۃ اللہ و برکاته
                                </div>
                            ) : (
                                <div
                                    dir="ltr"
                                    style={{
                                        fontFamily: "HvDtrail-Brandon",
                                        fontSize: 'clamp(14px, 2.5vw, 18px)',
                                        
                                        color: '#96A1BD',
                                        textAlign: 'left',
                                        lineHeight: '1.8'
                                    }}
                                >
                                    Beloved Huzur<sup style={{ fontSize: 'clamp(6px, 2.5vw, 10px)' }}>ABA</sup>,<br />
                                    Assalamu Alaikum wa Rahmatullahi wa Barakatuhu.
                                </div>
                            )}

                            {/* Content Area - Non-editable */}
                            <div style={{ 
                                backgroundColor: "rgba(0, 0, 0, 0.03)",
                                borderRadius: "6px",
                                padding: "12px",
                                marginTop: "20px",
                                minHeight: "200px"
                            }}>
                                <Paragraph
                                    style={{
                                        fontFamily: data.language === 'urdu' ? 'Noto Nastaliq Urdu' : "HvDtrail-Brandon",
                                        fontSize: "15px",
                                        fontWeight: 400,
                                        lineHeight: "1.8",
                                        direction: data.language === 'urdu' ? 'rtl' : 'ltr',
                                        textAlign: data.language === 'urdu' ? 'right' : 'left',
                                        color: '#494646',
                                        margin: 0,
                                        whiteSpace: 'pre-wrap'
                                    }}
                                >
                                    {data.content}
                                </Paragraph>
                            </div>

                            {/* Footer */}
                            {data.language === 'urdu' ? (
                                <div style={{
                                    textAlign: 'left',
                                    marginTop: '24px',
                                    fontFamily: 'Noto Nastaliq Urdu',
                                    fontSize: 'clamp(14px, 2.5vw, 18px)',
                                    color: '#96A1BD',
                                    lineHeight: '2',
                                    direction: 'rtl',
                                    paddingLeft: '12px'
                                }}>
                                    <div>والسلام</div>
                                    <div style={{ marginTop: '8px' }}>خاکسار آپ کا فرمانبردار</div>
                                </div>
                            ) : (
                                <div style={{
                                    textAlign: 'left',
                                    marginTop: '24px',
                                    fontFamily: "HvDtrail-Brandon",
                                    fontSize: 'clamp(14px, 2.5vw, 18px)',
                                    color: '#96A1BD',
                                    lineHeight: '2',
                                    fontStyle: 'italic',
                                    direction: 'ltr'
                                }}>
                                    <div>Wassalam,</div>
                                    <div style={{ marginTop: '8px' }}>Your Obedient Servant</div>
                                </div>
                            )}
                        </div>
                    </Card>

                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Button
                            type="primary"
                            onClick={onSend}
                            style={{
                                marginBottom: "24px",
                                backgroundColor: "#755B48",
                                borderColor: "#755B48",
                                fontFamily: "HvDtrail-Brandon",
                                fontSize: "32px",
                                fontWeight: 200,
                                lineHeight: "30.76px",
                                textAlign: "center",
                                height: "auto",
                                padding: "10px 0",
                                borderRadius: "20px",
                                width: "200px",
                                color: "white",
                                maxWidth: "80%",
                            }}
                        >
                            Send
                        </Button>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}