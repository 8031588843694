import React from "react";
import { Card, Typography, List, Row, Col, Progress } from "antd";
import { Link } from 'react-router-dom';
import { UserOutlined, LogoutOutlined, EditFilled, MenuOutlined, LoginOutlined, DeleteOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useAuth } from "react-oidc-context";
import { formatDistanceToNow } from 'date-fns';
import { loadLetterHistory } from "../store/app/app.slice";
import { useDispatch } from "react-redux";
import dashboardImage from '../assets/images/dasboard-image.svg';  // Add this import at the top
import clock from '../assets/images/clock_icon.svg';
import { format } from 'date-fns';
import logout from "../assets/images/logout_icon.svg";
const { Title, Text } = Typography;


const styles = {
  layoutContent: {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    backgroundColor: "#F4EDE6"
  },
  headerCard: {
    color: "white",
    fontFamily: "HvDtrail-Brandon-Thin",
    borderBottomLeftRadius: "200px",
    background: "linear-gradient(to bottom, #755B48, #301D14)",
    padding: "50px",
    position: "relative",
    zIndex: 0, // make sure this is below the top card
    marginBottom: "-60px", // allow overlap
  },
  
  topCard: {
    position: "relative",
    zIndex: 1,
    margin: "10px 10px 0px 20px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    backgroundColor: "#fff",
  },
  contentCard: {
    borderRadius: "5px",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },
  arabicText: {
    fontSize: "24px",
    marginTop: "10px",
  },
  backLink: {
    color: "white",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    display: "flex",
    alignItems: "center",
    marginTop: "20px",
    padding: "10px",
    flexDirection: 'column',
  }
};

export default function Dashboard() {
  const { loggedInUser, nextLetterInfo } = useSelector((state) => state.app);
  const { daysRemaining, progress } = nextLetterInfo;
  const [lastLetterDate, setLastLetterDate] = React.useState(null);
  const auth = useAuth();
  const [drafts, setDrafts] = React.useState([]);
  const dispatch = useDispatch();
  const formatAddress = (user) => {
    if (!user) return '';
    
    const addressParts = [
      user.streetLine1,
      user.streetLine2,
      user.city,
      user.state,
      user.zipCode,
      user.country?.defaultLabel
    ].filter(Boolean);
    
    return addressParts.join(', ');
  };


  React.useEffect(() => {
    dispatch(loadLetterHistory({
      callbackSuccess: (response) => {
        if (response && response.length > 0) {
          // Sort letters by date and get the most recent one
          const sortedLetters = response.sort((a, b) => new Date(b.created) - new Date(a.created));
          const mostRecentDate = new Date(sortedLetters[0].created);
          setLastLetterDate(mostRecentDate);
        }
      }
    }));
  }, [dispatch]);
  React.useEffect(() => {
    try {
      const letterDrafts = JSON.parse(localStorage.getItem("letterDrafts") || "[]");
      const flattenedDrafts = letterDrafts.map(draftObj => {
        const uuid = Object.keys(draftObj)[0];
        return { uuid, ...draftObj[uuid] };
      });
      const sortedDrafts = flattenedDrafts.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setDrafts(sortedDrafts);
    } catch (error) {
      console.error("Error loading drafts:", error);
      setDrafts([]);
    }
  }, []);

  const truncateContent = (content) => {
    if (!content) return '';
    return content.length > 50 ? `${content.substring(0, 50)}...` : content;
  };
  const handleDeleteDraft = (e, uuid) => {
    e.stopPropagation(); // Prevent triggering the List.Item onClick
    try {
      const letterDrafts = JSON.parse(localStorage.getItem("letterDrafts") || "[]");
      const updatedDrafts = letterDrafts.filter(draft => !draft[uuid]);
      localStorage.setItem("letterDrafts", JSON.stringify(updatedDrafts));
      setDrafts(drafts.filter(draft => draft.uuid !== uuid));
    } catch (error) {
      console.error("Error deleting draft:", error);
    }
  };

  const username = loggedInUser?.firstName;

  return (
    <div className="layout-content" style={styles.layoutContent}>
      <Card bordered={false} style={{ ...styles.headerCard, position: "relative" }}>
        <Row align="middle">
          <Col span={20}>
            <Title level={1} style={{ margin: 0, color: "white", padding: "10px" }}>Dashboard</Title>
            <Text style={{ margin: 0, color: "white", padding: "10px" }}>LETTER TO HUZUR (ABA)</Text>
          </Col>
          <Col span={4} style={{ textAlign: "right", padding: "10px" }}>
            {auth.isAuthenticated ? (
              <Link to="/" onClick={() => auth.signoutRedirect({ post_logout_redirect_uri: window.location.href })}>
              <img src={logout} alt="logout" style={{ width: "24px", height: "24px" }} />
            </Link>
            ) : (
              <Link to="/" onClick={() => auth.signinRedirect()}>
                <LoginOutlined style={{ fontSize: "24px", color: "white" }} />
              </Link>
            )}
          </Col>
        </Row>
        <Text style={{ ...styles.arabicText, ...styles.backLink }}>{username} السلام علیکم </Text>
      </Card>

      <Card bordered={false} style={{ ...styles.contentCard, ...styles.topCard }}>
  <div style={{ padding: "10px" }}>
    <Row justify="space-between" align="middle" style={{ fontFamily: "HvDtrail-Brandon" }}>
      <Col>
        <Text type="secondary">Last Letter: {new Date(lastLetterDate).toLocaleDateString()} </Text>
      </Col>
      <Col>
        <img src={clock} alt="Clock Icon" style={{ width: "30px", height: "30px",marginLeft: "15px" }} />
      </Col>
    </Row>

    <div >
      <Text  style={{ fontSize: "17px",fontFamily: "HvDtrail-Brandon" }}>
        <span style={{ fontFamily: "HvDtrail-Brandon", fontSize: "24px", fontWeight: "700" }}>{Math.max(0, daysRemaining)}</span> DAYS
      </Text>
    </div>

    <Progress percent={progress} showInfo={false} strokeColor="#6B4F4F" strokeWidth={5}style={{ marginBottom: "10px" }} />

   <div >
            
            <Text type="secondary" style={{ fontSize: "15px",fontFamily: "HvDtrail-Brandon" }}>
              {daysRemaining === 0 ? "Write a letter to Huzur now!" : "Time to next Letter"}
            </Text>
          
          </div>
  </div>
</Card>


      <Row gutter={[16, 16]} style={{ margin: '1px 10px', fontFamily: "HvDtrail-Brandon" }}>
        <Col xs={12}>
        
            
            <Link to="/letter" style={{ textDecoration: 'none' }}>
              <Card bordered={false} hoverable style={{
                ...styles.contentCard,
                margin: "10px 5px",
                height: '120px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}>
                <div style={{ textAlign: 'center' }}>
                  <EditFilled 
                    style={{ 
                      fontSize: '40px', 
                      marginBottom: '10px', 
                      color: ' #755B48'
                    }}
                  />
                  <Text strong style={{ 
                    display: 'block', 
                    fontSize: '20px',
                    fontFamily: "HvDtrail-Brandon",
                    color: "#634C3D"
                  }}>
                    NEW LETTER
                  </Text>
                </div>
              </Card>
            </Link>
       
        </Col>

        <Col xs={12}>
          <Link to="/history" style={{ textDecoration: 'none' }}>
            <Card bordered={false} hoverable style={{
              ...styles.contentCard,
              height: '120px',
              margin: "10px 5px",

              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            
            }}>
              <div style={{ textAlign: 'center' }}>
                <MenuOutlined style={{ fontSize: '40px', marginBottom: '10px', color: '#755B48' }} />
                <Text strong style={{ display: 'block', fontSize: '20px', fontFamily: "HvDtrail-Brandon" ,color: "#634C3D"}}>HISTORY</Text>
              </div>
            </Card>
          </Link>
        </Col>
      </Row>

      <Row gutter={[16, 16]} style={{
        margin: '20px 15px',
        overflowY: 'auto',
        maxHeight: '1000px',
        msOverflowStyle: 'none',
        scrollbarWidth: 'none',
        '&::-webkit-scrollbar': {
          display: 'none'
        }
      }}>
        <Col span={24}>
        {drafts.length > 0 ? (

          <Card bordered={false} style={{ borderRadius: '5px' }}
            title={<Title level={4} style={{ color: '#6B4F4F',fontFamily: "HvDtrail-Brandon" }}>Recent Drafts</Title>}
          >
            <List
              dataSource={drafts}
              renderItem={(draft) => (
                <List.Item
                  style={{ padding: '16px', borderBottom: '1px solid #f0f0f0', cursor: 'pointer',fontFamily: "HvDtrail-Brandon" }}
                  onClick={() => {
                    const draftToLoad = {
                      [draft.uuid]: {
                        content: draft.content,
                        category: draft.category,
                        language: draft.language,
                        timestamp: draft.timestamp,
                        address: draft.address || formatAddress(loggedInUser) // Use formatted address

                      }
                    };
                    localStorage.setItem('selectedDraft', JSON.stringify(draftToLoad));
                    window.location.href = '/letter';
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '8px' }}>
                      <Text strong style={{ color: '#6B4F4F' }}>
                        {draft.category ? draft.category.replace(/_/g, ' ').toUpperCase() : 'No Category'}
                      </Text>
                      <div style={{ 
                      display: 'flex', 
                      justifyContent: 'space-between', 
                      alignItems: 'center'
                    }}>
                      <Text type="secondary" style={{fontSize: '12px',fontFamily: "HvDtrail-Brandon"}}>
                        {formatDistanceToNow(new Date(draft.timestamp), { addSuffix: true })}
                      </Text>
                      <DeleteOutlined 
                        onClick={(e) => handleDeleteDraft(e, draft.uuid)}
                        style={{ 
                          color: '#6B4F4F',
                          fontSize: '16px',
                          paddingLeft: '10px',
                          cursor: 'pointer',
                          '&:hover': {
                            color: '#ff4d4f'
                          }
                        }}
                      />
                    </div>
                    </div>
                    <Text style={{ color: '#494646',textAlign: draft.language === 'urdu' ? 'right' : 'left' }}>{truncateContent(draft.content || 'Empty draft')}</Text>
                    <div style={{ marginTop: '8px', display: 'flex', justifyContent: 'flex-end' }}>
                      <Text type="secondary" style={{ fontSize: '12px' }}>
                        Language: {draft.language || 'Not specified'}
                      </Text>
                    </div>
                  </div>
                </List.Item>
              )}
            />
          </Card>
          ) : (
            <div style={{ 
              textAlign: 'center',
              overflowY: 'hidden',
              padding: '40px 0',
              marginTop: '20px'
            }}>
              <img 
                src={dashboardImage} 
                alt="No drafts" 
                style={{
                  width: '300px',
                  maxWidth: '100%'
                }}
              />
             
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
}
