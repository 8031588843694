import React, { useState, useEffect } from "react";
import LetterOptions from "./LetterOptions";
import LetterContent from "./LetterContent";
import LetterReview from "./LetterReview";
import {
    Card,
    Typography,
    Select,
    Input,
    Button,
    Row,
    Col,
    message,
} from "antd";
import {
    MailOutlined,
    UserOutlined,
    DownOutlined,
    CreditCardOutlined,
    HistoryOutlined,
    ArrowLeftOutlined
} from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { GlobalOutlined } from '@ant-design/icons';
import { useAuth } from "react-oidc-context";
import { API_BASE_URI } from '../constants'
import {useDispatch, useSelector} from "react-redux";
import {sendLetter} from "../store/app/app.slice";
import {NewLetterDto} from "postbox-rest-client";
import ReviewLetterModal from "./LetterReview";
import { v4 as uuidv4 } from 'uuid';
import { useHistory } from 'react-router-dom';
import { categories, languages } from "../utils/language";
import '../assets/styles/main.css';

// ... keep other imports ...

const { Title, Text } = Typography;
const { Option } = Select;
const { TextArea } = Input;

// Note: You need to import these fonts in your global CSS or index.html
// @import url('https://fonts.googleapis.com/css2?family=Lato:wght@800&display=swap');
// For HvDTrial Brandon Grotesque, you might need to use a custom font loader or include it in your project assets

// export default function LetterPage() {
//     const dispatch = useDispatch();
//     const { loggedInUser } = useSelector((state) => state.app);
//     const history = useHistory();
//     const [category, setCategory] = useState(null);
//     const [content, setContent] = useState("");
//     const [wordCount, setWordCount] = useState(0);
//     const [language, setLanguage] = useState(null);
//     const [draftId, setDraftId] = useState(() => {
//         // Generate a new UUID for each visit
//         const newDraftId = uuidv4();
//         return newDraftId;
//     });

//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const formatAddress = (user) => {
//         const addressParts = [
//             user.streetLine1,
//             user.streetLine2,
//             user.city,
//             user.state,
//             user.zipCode,
//             user.country.defaultLabel
//         ].filter(Boolean); // This removes any null/undefined/empty values
        
//         return addressParts.join(', ');
//     };
//     const [address, setAddress] = React.useState(formatAddress(loggedInUser));
//     const handleAddressChange = (e) => {
//         setAddress(e.target.value);
//       };
      
//     React.useEffect(() => {
//         // Load the selected draft when the letter page loads
//         const selectedDraft = localStorage.getItem('selectedDraft');
//         if (selectedDraft) {
//             const draftData = JSON.parse(selectedDraft);
//             // Get the UUID (first and only key of the object)
//             const uuid = Object.keys(draftData)[0];
//             // Get the draft content
//             const draft = draftData[uuid];
            
//             // Set your form/editor state with the draft data

//             setDraftId(uuid);
//             setContent(draft.content);
//             setCategory(draft.category);
//             setLanguage(draft.language);
//              // Show a success message
//             // Clear the selected draft from localStorage
//             localStorage.removeItem('selectedDraft');
//         }
//     }, []);
//     // Modify your button click handler
//     const handleSendClick = () => {
//         setIsModalVisible(true);
//     };
//     const sendNewLetter = () => {
//         const formattedAddress = formatAddress(loggedInUser);
//         const newLetter = {
//             content: content,
//             language: language,
//             category: category,
//             address: address
//         }
        
//         dispatch(sendLetter({finalData: {newLetter}, callbackSuccess: () => {
//             // Reset the fields
//             const letterDrafts = JSON.parse(localStorage.getItem("letterDrafts") || "[]");
//             const updatedDrafts = letterDrafts.filter(draft => Object.keys(draft)[0] !== draftId);
//             localStorage.setItem("letterDrafts", JSON.stringify(updatedDrafts));
//             setCategory(null);
//             setContent("");
//             setLanguage(null);

//             message.success("Letter sent successfully!", 2, () => {
//                 history.push('/'); // Use history.push instead of navigate
//             });

//         },
//         callbackError: (error) => {
//             console.error('Error saving letter:', error);
//             message.error("Failed to save letter. Please try again.");
//         }
        
//         }))
//     } 
    
//     // Update word count whenever content changes
//     useEffect(() => {
//         const words = content.trim().split(/\s+/);
//         setWordCount(content.trim() === '' ? 0 : words.length);
//     }, [content]);

//     const handleContentChange = (e) => {
//         const newContent = e.target.value;
//         const words = newContent.trim().split(/\s+/);
//         const newWordCount = newContent.trim() === '' ? 0 : words.length;
        
//         if (newWordCount <= 100) {
//             setContent(newContent);
//         }
//     };
//     const getCategoryOptions = () => {
//         // Default to english if no language is selected
//         if (!language || language === 'english') {
//             return categories.english;
//         } else if (language === 'urdu') {
//             return categories.urdu;
//         }
//         // For other languages, fallback to English
//         return categories.english;
//     };

//     // useEffect(() => {
//     //     const letterDrafts = JSON.parse(localStorage.getItem("letterDrafts") || "[]");
//     //     if (letterDrafts.length > 0) {
//     //         // Get the most recent draft
//     //         const latestDraft = letterDrafts[letterDrafts.length - 1];
//     //         setContent(latestDraft.content || "");
//     //         setCategory(latestDraft.category || null);
//     //         setLanguage(latestDraft.language || null);
//     //     }
//     // }, []);

//     // Save draft with timestamp
//     useEffect(() => {
//         if (content || category || language) {
//             const letterDrafts = JSON.parse(localStorage.getItem("letterDrafts") || "[]");
            
//             // Create draft data object
//             const draftData = {
//                 content,
//                 category,
//                 language,
//                 timestamp: new Date().toISOString()
//             };

//             // Find if there's an existing draft with this UUID
//             const existingDraftIndex = letterDrafts.findIndex(draft => Object.keys(draft)[0] === draftId);

//             if (existingDraftIndex !== -1) {
//                 // Update existing draft
//                 letterDrafts[existingDraftIndex] = { [draftId]: draftData };
//             } else {
//                 // Add new draft
//                 letterDrafts.push({ [draftId]: draftData });
//                 // Keep only the last 5 drafts
//                 if (letterDrafts.length > 5) {
//                     letterDrafts.splice(0, letterDrafts.length - 5);
//                 }
//             }

//             localStorage.setItem("letterDrafts", JSON.stringify(letterDrafts));
//         }
//     }, [content, category, language, draftId]);

//     return (
//         <div
//             className="layout-content"
//             style={{
//                 height: "100vh",
//                 // overflow: "hidden",    // Changed from "auto"
//                 overflowX: "hidden",    // Added to enable horizontal scroll
//                 overflowY: "auto",  // Kept to disable vertical scroll
//                 display: "flex",
//                 msOverflowStyle: 'none',  // Hide scrollbar in IE
//                 scrollbarWidth: 'none',   // Hide scrollbar in Firefox
//                 '&::-webkit-scrollbar': { // Hide scrollbar in Chrome/Safari/newer Edge
//                     display: 'none'
//                 },
//                 flexDirection: "column"
//             }}
//         >
//             <Row justify="center" align="middle" style={{ flex: 1 }}>
//                 <Col xs={24} sm={22} md={20} lg={18} xl={16}>

//                     <Card bordered={false} className="criclebox h-full" style={{ padding: "32px" }}>
//                         <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
//                             <Link to="/" style={{ marginRight: '16px' }}>
//                                 <ArrowLeftOutlined style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer' }} />
//                             </Link>
//                             <Title
//                                 level={3}
//                                 style={{
//                                     fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
//                                     fontSize: "30.74px",
//                                     fontWeight: 700,
//                                     lineHeight: "43.95px",
//                                     textAlign: "left",
//                                     marginBottom: 0,
//                                     color: "#6B4F4F"
//                                 }}
//                             >
//                                 Send a Letter
//                             </Title>
//                         </div>
//                         <Text
//                             style={{
//                                 display: "block",
//                                 fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
//                                 fontSize: "17px",
//                                 fontWeight: 700,
//                                 lineHeight: "24.31px",
//                                 textAlign: "left",
//                                 marginBottom: "24px",
//                                 color: "#96A1BD"
//                             }}
//                         >
//                             Max. 100 words allowed. A letter pad will be included automatically.
//                         </Text>
//                         <Select
//                             style={{
//                                 width: "100%",
//                                 marginBottom: "24px",
//                                 boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
//                             }}
//                             placeholder={<span style={{
//                                 fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
//                                 fontSize: "18px",
//                                 fontWeight: 700,
//                                 lineHeight: "15.73px",
//                                 color: "#494646",
//                                 opacity: 0.5
//                             }}>
//                                 Choose Category
//                             </span>}
//                             dropdownStyle={{ borderRadius: "6px" }}
//                             suffixIcon={<DownOutlined style={{ color: "#56B3B3", fontWeight: "bold" }} />}
//                             value={category}
//                             onChange={setCategory}
//                         >
//                             {Object.entries(getCategoryOptions()).map(([value, label]) => (
//                                 <Option key={value} value={value} style={{
//                                     fontFamily: language === 'urdu' ? 'Noto Nastaliq Urdu' : "'HvDTrial Brandon Grotesque', sans-serif",
//                                     fontSize: language === 'urdu' ? '15px' : '14px',
//                                     padding: language === 'urdu' ? '8px 12px' : '4px 12px',
//                                     lineHeight: language === 'urdu' ? '2' : 'inherit'
//                                 }}>
//                                     {label}
//                                 </Option>
//                             ))}
//                         </Select>
//                         <Select
//                             style={{
//                                 width: "100%",
//                                 marginBottom: "24px",
//                                 boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
//                             }}
//                             placeholder={<span style={{
//                                 fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
//                                 fontSize: "18px",
//                                 fontWeight: 700,
//                                 lineHeight: "15.73px",
//                                 color: "#494646",
//                                 opacity: 0.5
//                             }}>
//                                 Choose Language
//                             </span>}
//                             dropdownStyle={{ borderRadius: "6px" }}
//                             suffixIcon={<GlobalOutlined style={{ color: "#56B3B3", fontWeight: "bold" }} />}
//                             value={language}
//                             onChange={setLanguage}
//                         >
//                             {Object.entries(languages).map(([value, label]) => (
//                                 <Option key={value} value={value}>
//                                     {label}
//                                 </Option>
//                             ))}
//                         </Select>

//                         <Card
//                             bordered={false}
//                             style={{
//                                 borderRadius: '20px',
//                                 padding: '32px',
//                                 backgroundColor: '#fff',
//                                 boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
//                                 marginBottom: '24px',
//                                 height: 'calc(100vh - 250px)', // Fixed height based on viewport
//                                 position: 'relative',
//                                 display: 'flex',
//                                 flexDirection: 'column',
//                                 overflow: 'auto',
//                                 msOverflowStyle: 'none',  // Hide scrollbar in IE
//                                 scrollbarWidth: 'none',   // Hide scrollbar in Firefox
//                                 '&::-webkit-scrollbar': { // Hide scrollbar in Chrome/Safari/newer Edge
//                                     display: 'none'
//                                 }
//                             }}
//                         >
                           
//                                 {/* Arabic/Urdu Header - Centered */}
//                                 <div
//                                     dir="rtl"
//                                     lang="ur"
//                                     style={{
//                                         fontFamily: 'AmiriRegular',
//                                         fontSize: 'clamp(14px, 2vw, 18px)', // responsive base font size
//                                         textAlign: 'center',
//                                         color: '#96A1BD',
//                                         lineHeight: '2.2',
//                                         marginBottom: '16px',
//                                     }}
//                                 >
//                                     <div style={{
//                                         fontSize: 'clamp(18px, 3vw, 24px)',
//                                         fontWeight: 'bold'
//                                     }}>بسم الله الرحمن الرحيم</div>
//                                     <div>
//                                         <Row style={{
//                                             fontSize: 'clamp(14px, 2.5vw, 18px)',
//                                             fontWeight: 'bold',
//                                             padding: '12px'
//                                         }}>
//                                             <Col xs={24} sm={12} >نحمده و نصلي علی رسوله الكريم <sup style={{ fontSize: 'clamp(6px, 2.5vw, 10px)' }}>ﷺ</sup></Col>

//                                             <Col xs={24} sm={12} >و علی عبده المسيح الموعودؑ</Col>
//                                         </Row>
//                                     </div>
//                                     <div style={{
//                                         fontFamily: 'Noto Nastaliq Urdu',
//                                         fontSize: 'clamp(14px, 2vw, 18px)'
//                                     }}>خُدا کے فضل اور رحم کے ساتھ - ھو الناصر</div>




//                                     {/* Date (Top right) */}
//                                     <div style={{ textAlign: 'right', marginBottom: '16px', fontSize: '16px', fontFamily: "'HvDTrial Brandon Grotesque', serif", color: '#96A1BD' }}>
//                                         {new Date().toLocaleDateString('en-US', {
//                                             month: 'long',
//                                             day: 'numeric',
//                                             year: 'numeric'
//                                         }).replace(',', ', ')}
//                                     </div>
//                                     {/* English Greeting - Left Aligned */}
//                                     {language === 'urdu' ? (
//                                         <div
//                                             dir="rtl"
//                                             lang="ur"
//                                             style={{
//                                                 fontFamily: 'Noto Nastaliq Urdu',
//                                                 fontSize: 'clamp(14px, 2.5vw, 18px)',
//                                                 textAlign: 'right',
//                                                 color: '#96A1BD',
//                                                 lineHeight: '2'
//                                             }}
//                                         >
//                                             پیارے حضور! <br />
//                                             السلام علیکم و رحمۃ اللہ و برکاته
//                                         </div>
//                                     ) : (
//                                         <div
//                                             dir="ltr"
//                                             style={{
//                                                 fontFamily: "'HvDTrial Brandon Grotesque', serif",
//                                                 fontSize: 'clamp(14px, 2.5vw, 18px)',
//                                                 fontStyle: 'italic',
//                                                 color: '#96A1BD',
//                                                 textAlign: 'left',
//                                                 lineHeight: '1.8'
//                                             }}
//                                         >
//                                             Beloved Huzur<sup style={{ fontSize: 'clamp(6px, 2.5vw, 10px)' }}>ABA</sup>,<br />
//                                             Assalamu Alaikum wa Rahmatullahi wa Barakatuhu.

//                                         </div>
//                                     )}

//                                     {/* Body Text Area */}
//                                     <div style={{ position: 'relative' }}>
//                                         <TextArea
//                                             placeholder={language === 'urdu' ? "تحریر کریں..." : "Type..."}
//                                             autoSize={{ minRows: 15, maxRows: 100 }}
//                                             style={{
//                                                 backgroundColor: "rgba(0, 0, 0, 0.03)",
//                                                 border: "none",
//                                                 borderRadius: "6px",
//                                                 padding: "12px",
//                                                 fontFamily: language === 'urdu' ? 'Noto Nastaliq Urdu' : "'HvDTrial Brandon Grotesque', sans-serif",
//                                                 fontSize: "15px",
//                                                 fontWeight: 400,
//                                                 lineHeight: "1.8",
//                                                 direction: language === 'urdu' ? 'rtl' : 'ltr',
//                                                 textAlign: language === 'urdu' ? 'right' : 'left'
//                                             }}
//                                             value={content}
//                                             onChange={handleContentChange}
//                                             dir={language === 'urdu' ? 'rtl' : 'ltr'}
//                                         />
//                                         <div style={{
//                                             position: 'absolute',
//                                             bottom: '10px',
//                                             left: '10px', // ⬅️ always bottom-left regardless of language
//                                             padding: '5px 10px',
//                                             backgroundColor: 'rgba(107, 79, 79, 0.1)',
//                                             borderRadius: '4px',
//                                             color: wordCount === 100 ? '#ff4d4f' : '#6B4F4F',
//                                             fontFamily: language === 'urdu' ? 'Noto Nastaliq Urdu' : "'HvDTrial Brandon Grotesque', sans-serif",
//                                             fontSize: '14px'
//                                         }}>
//                                             {language === 'urdu' ? `${wordCount}/۱۰۰ الفاظ` : `${wordCount}/100 words`}
//                                         </div>
//                                     </div>

//                                     {/* Footer Text */}
//                                     {language === 'urdu' ? (
//                                         <div style={{
//                                             textAlign: 'left',
//                                             marginTop: '24px',
//                                             fontFamily: 'Noto Nastaliq Urdu',
//                                             fontSize: 'clamp(14px, 2.5vw, 18px)',
//                                             color: '#96A1BD',
//                                             lineHeight: '2',
//                                             direction: 'rtl',
//                                             paddingLeft: '12px'
//                                         }}>
//                                             <div>والسلام</div>
//                                             <div style={{ marginTop: '8px' }}>خاکسار آپ کا فرمانبردار</div>
//                                         </div>
//                                     ) : (
//                                         <div style={{
//                                             textAlign: 'left',
//                                             marginTop: '24px',
//                                             fontFamily: "'HvDTrial Brandon Grotesque', serif",
//                                             fontSize: 'clamp(14px, 2.5vw, 18px)',
//                                             color: '#96A1BD',
//                                             lineHeight: '2',
//                                             fontStyle: 'italic',
//                                             direction: 'ltr'
//                                         }}>
//                                             <div>Wassalam,</div>
//                                             <div style={{ marginTop: '8px' }}>Your Obedient Servant</div>
//                                         </div>
//                                     )}

//                                 </div>



//                         </Card>


//                         <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
//                             <Button
//                                 type="primary"
//                                 size="large"
//                                 style={{
//                                     marginBottom: "24px",
//                                     backgroundColor: "#6B4F4F",
//                                     borderColor: "#6B4F4F",
//                                     fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
//                                     fontSize: "32px",
//                                     fontWeight: 200,
//                                     lineHeight: "30.76px",
//                                     textAlign: "center",
//                                     height: "auto",
//                                     padding: "10px 0",
//                                     borderRadius: "20px",
//                                     width: "200px",
//                                     maxWidth: "80%",
//                                 }}
//                                 onClick={handleSendClick}
//                             >
//                                 send
//                             </Button>

//                             <ReviewLetterModal
//                                 isVisible={isModalVisible}
//                                 onClose={() => setIsModalVisible(false)}
//                                 title={getCategoryOptions()[category]}
//                                 language={languages[language]}
//                                 content={content} // Replace with your actual letter content
//                                 address={address}
//                                 onSend={sendNewLetter}
//                                 onAddressChange={handleAddressChange}
//                             />
//                         </div>

//                     </Card>
//                 </Col>
//             </Row>
//             {/* Updated menu bar */}
//             {/* <div style={{
//                 position: 'fixed',
//                 bottom: 20,
//                 left: 0,
//                 right: 0,
//                 display: 'flex',
//                 justifyContent: 'space-between',
//                 alignItems: 'center',
//                 padding: '15px 20px',
//                 backgroundColor: 'white',
//                 boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.15)',
//                 zIndex: 1001 // Increased z-index to ensure it's above the card
//             }}>
//                 <div style={{ width: '12px' }}></div>
//                 <Link to="/">
//                     <CreditCardOutlined 
//                         style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer' }} 
//                     />
//                 </Link>
//                 <Link to="/history">
//                     <HistoryOutlined 
//                         style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer' }} 
//                     />
//                 </Link>
//             </div> */}
//         </div>
//     );
// }



export default function LetterPage() {
    const dispatch = useDispatch();
    const history = useHistory();
    const { loggedInUser } = useSelector((state) => state.app);
    const [currentStep, setCurrentStep] = useState(1);
    const formatAddress = (user) => {
        if (!user) return '';
        
        const addressParts = [
            user.streetLine1,
            user.streetLine2,
            user.city,
            user.state,
            user.zipCode,
            user.country?.defaultLabel // Using optional chaining
        ].filter(Boolean); // Remove any null/undefined/empty values
        
        return addressParts.join(', ');
    };
    const [letterData, setLetterData] = useState({
        category: null,
        language: null,
        content: "",
        address: formatAddress(loggedInUser),
        draftId: uuidv4()
    });


    const sendNewLetter = () => {
        const newLetter = {
            content: letterData.content,
            language: letterData.language,
            category: letterData.category,
            address: letterData.address
        };
        
        dispatch(sendLetter({
            finalData: { newLetter }, 
            callbackSuccess: () => {
                // Reset the fields and clear draft
                const letterDrafts = JSON.parse(localStorage.getItem("letterDrafts") || "[]");
                const updatedDrafts = letterDrafts.filter(draft => 
                    Object.keys(draft)[0] !== letterData.draftId
                );
                localStorage.setItem("letterDrafts", JSON.stringify(updatedDrafts));

                // Reset the state
                setLetterData({
                    category: null,
                    language: null,
                    content: "",
                    address: "",
                    draftId: uuidv4()
                });
                
                // Show success message and redirect
                message.success("Letter sent successfully!", 2, () => {
                    history.push('/');
                });
            },
            callbackError: (error) => {
                console.error('Error saving letter:', error);
                message.error("Failed to save letter. Please try again.");
            }
        }));
    };

    // Save draft whenever letterData changes
    useEffect(() => {
        if (letterData.content || letterData.category || letterData.language) {
            const letterDrafts = JSON.parse(localStorage.getItem("letterDrafts") || "[]");
            
            const draftData = {
                content: letterData.content,
                category: letterData.category,
                language: letterData.language,
                address: letterData.address,
                timestamp: new Date().toISOString()
            };

            // Find if there's an existing draft with this UUID
            const existingDraftIndex = letterDrafts.findIndex(
                draft => Object.keys(draft)[0] === letterData.draftId
            );

            if (existingDraftIndex !== -1) {
                // Update existing draft
                letterDrafts[existingDraftIndex] = { [letterData.draftId]: draftData };
            } else {
                // Add new draft
                letterDrafts.push({ [letterData.draftId]: draftData });
                // Keep only the last 5 drafts
                if (letterDrafts.length > 5) {
                    letterDrafts.splice(0, letterDrafts.length - 5);
                }
            }

            localStorage.setItem("letterDrafts", JSON.stringify(letterDrafts));
        }
    }, [letterData]);

    // Load draft on component mount
    useEffect(() => {
        const selectedDraft = localStorage.getItem('selectedDraft');
        if (selectedDraft) {
            const draftData = JSON.parse(selectedDraft);
            const uuid = Object.keys(draftData)[0];
            const draft = draftData[uuid];
            
            setLetterData({
                ...letterData,
                draftId: uuid,
                content: draft.content,
                category: draft.category,
                language: draft.language,
                address: draft.address
            });

            localStorage.removeItem('selectedDraft');
        }
    }, []);

    const handleNext = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePrevious = () => {
        setCurrentStep(currentStep - 1);
    };

    const handleDataUpdate = (newData) => {
        setLetterData({ ...letterData, ...newData });
    };

    const renderStep = () => {
        switch (currentStep) {
            case 1:
                return (
                    <LetterOptions 
                        data={letterData}
                        onDataUpdate={handleDataUpdate}
                        onNext={handleNext}
                    />
                );
            case 2:
                return (
                    <LetterContent
                        data={letterData}
                        onDataUpdate={handleDataUpdate}
                        onNext={handleNext}
                        onPrevious={handlePrevious}
                    />
                );
            case 3:
                return (
                    <LetterReview
                        data={letterData}
                        onPrevious={handlePrevious}
                        onSend={sendNewLetter}
                    />
                );
            default:
                return null;
        }
    };

    return (
        <div className="layout-content" style={{
            height: "100vh",
            overflowX: "hidden",
            overflowY: "hidden",
            display: "flex",
            flexDirection: "column"
        }}>
            {renderStep()}
        </div>
    );
}