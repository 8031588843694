import React, { useState, useEffect } from "react";
import { Card, Button, Row, Col, Input, Typography } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from 'react-router-dom';
import '../assets/styles/main.css';
import letterHeader from '../assets/images/letter-header.svg';  // Add this import

const { Title, Text } = Typography;
const { TextArea } = Input;

export default function LetterContent({ data, onDataUpdate, onNext, onPrevious }) {
    const [wordCount, setWordCount] = useState(0);

    const handleContentChange = (e) => {
        const newContent = e.target.value;
        const words = newContent.trim().split(/\s+/);
        const newWordCount = newContent.trim() === '' ? 0 : words.length;
        
        if (newWordCount <= 100) {
            onDataUpdate({ content: newContent });
        }
    };

    // Update word count whenever content changes
    useEffect(() => {
        const words = data.content.trim().split(/\s+/);
        setWordCount(data.content.trim() === '' ? 0 : words.length);
    }, [data.content]);

    return (
        <Row justify="center" align="middle" style={{ flex: 1 }}>
            <Col xs={24} sm={22} md={20} lg={18} xl={16}>
                <Card bordered={false} className="criclebox h-full" style={{ padding: "32px" }}>
                
                            <ArrowLeftOutlined onClick={onPrevious} style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer', marginRight: '16px', marginBottom: '16px' }} />
                        
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                      
                        <Title level={3} style={{
                            fontFamily: "HvDtrail-Brandon",
                            fontSize: "30.74px",
                            fontWeight: 700,
                            lineHeight: "43.95px",
                            textAlign: "left",
                            marginBottom: 0,
                            color: "black"
                        }}>
                            Send a Letter
                        </Title>
                    </div>

                    {/* <Text style={{
                        display: "block",
                        fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                        fontSize: "17px",
                        fontWeight: 700,
                        lineHeight: "24.31px",
                        textAlign: "left",
                        marginBottom: "24px",
                        color: "#96A1BD"
                    }}>
                        Max. 100 words allowed. A letter pad will be included automatically.
                    </Text> */}

                    <Card
                        bordered={false}
                        style={{
                            borderRadius: '20px',
                            borderTopColor:"#D9C5BA",
                            borderBlockColor:"#D9C5BA",
                            borderTopWidth: "10px",
                            padding: '32px',
                            backgroundColor: '#fff',
                            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.05)',
                            marginBottom: '24px',
                            height: 'calc(100vh - 250px)',
                            position: 'relative',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'auto',
                            msOverflowStyle: 'none',
                            scrollbarWidth: 'none',
                            '&::-webkit-scrollbar': {
                                display: 'none'
                            }
                        }}
                    >
                        {/* Arabic/Urdu Header - Centered */}
                        <div
                            dir="rtl"
                            lang="ur"
                            style={{
                                fontFamily: 'AmiriRegular',
                                fontSize: 'clamp(14px, 2vw, 18px)',
                                textAlign: 'center',
                                color: '#96A1BD',
                                lineHeight: '2.2',
                                marginBottom: '16px',
                            }}
                        >
                                  <div style={{
                            textAlign: 'center',
                            marginBottom: '16px'
                        }}>
                            <img 
                                src={letterHeader}
                                alt="Letter Header"
                                style={{
                                    width: 'clamp(400px, 80%, 600px)',
                                    height: 'auto'
                                }}
                            />
                        </div>
                                  

                        

                            {/* Greeting */}
                            {data.language === 'urdu' ? (
                                <div
                                    dir="rtl"
                                    lang="ur"
                                    style={{
                                        fontFamily: 'Noto Nastaliq Urdu',
                                        fontSize: 'clamp(14px, 2.5vw, 18px)',
                                        textAlign: 'right',
                                        color: '#96A1BD',
                                        lineHeight: '2'
                                    }}
                                >
                                    پیارے حضور! <br />
                                    السلام علیکم و رحمۃ اللہ و برکاته
                                </div>
                            ) : (
                                <div
                                    dir="ltr"
                                    style={{
                                        fontFamily: "HvDtrail-Brandon",
                                        fontSize: 'clamp(14px, 2.5vw, 18px)',
                                        
                                        color: '#96A1BD',
                                        textAlign: 'left',
                                        lineHeight: '1.8'
                                    }}
                                >
                                    Beloved Huzur<sup style={{ fontSize: 'clamp(6px, 2.5vw, 10px)' }}>ABA</sup>,<br />
                                    Assalamu Alaikum wa Rahmatullahi wa Barakatuhu.
                                </div>
                            )}

                            {/* Content Area */}
                            <div style={{ position: 'relative' }}>
                                <TextArea
                                    placeholder={data.language === 'urdu' ? "تحریر کریں..." : "Type..."}
                                    autoSize={{ minRows: 15, maxRows: 100 }}
                                    style={{
                                        backgroundColor: "rgba(0, 0, 0, 0.03)",
                                        border: "none",
                                        borderRadius: "6px",
                                        padding: "12px",
                                        fontFamily: data.language === 'urdu' ? 'Noto Nastaliq Urdu' : "HvDtrail-Brandon",
                                        fontSize: "15px",
                                        fontWeight: 400,
                                        lineHeight: "1.8",
                                        direction: data.language === 'urdu' ? 'rtl' : 'ltr',
                                        textAlign: data.language === 'urdu' ? 'right' : 'left'
                                    }}
                                    value={data.content}
                                    onChange={handleContentChange}
                                    dir={data.language === 'urdu' ? 'rtl' : 'ltr'}
                                />
                                <div style={{
                                    position: 'absolute',
                                    bottom: '10px',
                                    left: '10px',
                                    padding: '5px 10px',
                                    backgroundColor: 'rgba(107, 79, 79, 0.1)',
                                    borderRadius: '4px',
                                    color: wordCount === 100 ? '#ff4d4f' : '#6B4F4F',
                                    fontFamily: data.language === 'urdu' ? 'Noto Nastaliq Urdu' : "HvDtrail-Brandon",
                                    fontSize: '14px'
                                }}>
                                    {data.language === 'urdu' ? `${wordCount}/۱۰۰ الفاظ` : `${wordCount}/100 words`}
                                </div>
                            </div>

                            {/* Footer */}
                            {data.language === 'urdu' ? (
                                <div style={{
                                    textAlign: 'left',
                                    marginTop: '24px',
                                    fontFamily: 'Noto Nastaliq Urdu',
                                    fontSize: 'clamp(14px, 2.5vw, 18px)',
                                    color: '#96A1BD',
                                    lineHeight: '2',
                                    direction: 'rtl',
                                    paddingLeft: '12px'
                                }}>
                                    <div>والسلام</div>
                                    <div style={{ marginTop: '8px' }}>خاکسار آپ کا فرمانبردار</div>
                                </div>
                            ) : (
                                <div style={{
                                    textAlign: 'left',
                                    marginTop: '24px',
                                    fontFamily: "HvDtrail-Brandon",
                                    fontSize: 'clamp(14px, 2.5vw, 18px)',
                                    color: '#96A1BD',
                                    lineHeight: '2',
                                    fontStyle: 'italic',
                                    direction: 'ltr'
                                }}>
                                    <div>Wassalam,</div>
                                    <div style={{ marginTop: '8px' }}>Your Obedient Servant</div>
                                </div>
                            )}
                        </div>
                    </Card>

                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Button
                            type="primary"
                            onClick={onNext}
                            disabled={!data.content.trim()}
                           
                                                            size="large"
                                                            style={{
                                                                marginBottom: "24px",
                                                                backgroundColor: "#755B48",
                                                                borderColor: "#755B48",
                                                                fontFamily: "HvDtrail-Brandon",
                                                                fontSize: "32px",
                                                                fontWeight: 200,
                                                                lineHeight: "30.76px",
                                                                textAlign: "center",
                                                                height: "auto",
                                                                padding: "10px 0",
                                                                borderRadius: "20px",
                                                                width: "200px",
                                                                color: "white",
                                                                maxWidth: "80%",
                                                            }}
                        >
                            Next
                        </Button>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}