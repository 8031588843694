import React, { useEffect } from "react";
import { Card, Typography, List, Row, Col, Progress, message, Spin } from "antd";
import { Link } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from "react-redux";
import { loadLetterHistory } from "../store/app/app.slice";
import logout from "../assets/images/logout_icon.svg";
import { useAuth } from "react-oidc-context";
import back from "../assets/images/back_button.svg";
const { Title, Text } = Typography;

const styles = {
  layoutContent: {
    height: "100vh",
    overflow: "hidden",
    display: "flex",
    flexDirection: "column",
    fontFamily: "HvDTrial-Brandon",
    backgroundColor: "#F4EDE6"
  },
  headerCard: {
    color: "white",
    fontFamily: "HvDtrail-Brandon-Thin",
    borderBottomLeftRadius: "200px",
    background: "linear-gradient(to bottom, #755B48, #301D14)",
    padding: "50px 50px 70px 50px",
    position: "relative",
    zIndex: 0, // make sure this is below the top card
    marginBottom: "-60px", // allow overlap
  },
  
  topCard: {
    position: "relative",
    zIndex: 1,
    margin: "10px 10px 0px 20px",
    boxShadow: "0 10px 30px rgba(0, 0, 0, 0.1)",
    borderRadius: "5px",
    backgroundColor: "#fff",
  },
  contentCard: {
    borderRadius: "5px",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
  },

  scrollbarStyles: {
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },

  backLink: {
    color: "white",
    fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
    display: "flex",
    alignItems: "left",
    marginTop: "20px",
    padding: "10px",
    fontSize: "16px",
    fontWeight: "bold",
    flexDirection: 'column',
  }
};

export default function History() {
  const dispatch = useDispatch();
  const { letterHistory, isLetterHistoryLoading, nextLetterInfo } = useSelector((state) => state.app);
  const auth = useAuth();
  useEffect(() => {
    dispatch(loadLetterHistory({}));
  }, [dispatch]);

  // Sort letters by creation date (ascending) and assign numbers
  const sortedLetters = letterHistory ? [...letterHistory].sort((a, b) =>
    new Date(a.created) - new Date(b.created)
  ).map((letter, index) => ({
    ...letter,
    numberByDate: index + 1
  })) : [];

  const { daysRemaining, progress } = nextLetterInfo;

  const handleItemClick = (item) => {
    message.info(`You clicked on Letter no. ${item.numberByDate} from ${new Date(item.created).toLocaleDateString()}`);
  };

  return (
    <div className="layout-content" style={styles.layoutContent}>
      <Card bordered={false} style={styles.headerCard}>
       
        <Row align="middle">
        <Col span={2}>
        <Link to="/" style={{ display: "flex", alignItems: "center" }}>
      <img src={back} alt="back" style={{ width: "24px", height: "24px" }} />
    </Link>
          </Col>
          <Col span={18}>
            <Title level={1} style={{ margin: 0, color: "white", padding: "10px" }}>History</Title>
            <Text style={{ margin: 0, color: "white", padding: "10px" }}>LETTER TO HUDHUR (ABA)</Text>
          </Col>
          <Col span={4} style={{ textAlign: "right" }}>
          <Link to="/" onClick={() => auth.signoutRedirect({ post_logout_redirect_uri: window.location.href })}>
                <img src={logout} alt="logout" style={{ width: "24px", height: "24px" }} />
              </Link>
          </Col>
        </Row>
        
      </Card>

      <Card bordered={false} style={{ ...styles.contentCard, ...styles.scrollbarStyles, ...styles.topCard }}>
        <div style={{ padding: "20px" }}>
          <div style={{ marginBottom: "20px" }}>
          <Text  style={{ fontSize: "17px",fontFamily: "HvDtrail-Brandon" }}>
        <span style={{ fontFamily: "HvDtrail-Brandon", fontSize: "24px", fontWeight: "700" }}>{Math.max(0, daysRemaining)}</span> DAYS
      </Text>
            <Text type="secondary" style={{ float: "right", fontFamily: "HvDtrail-Brandon" }}>Time to next Letter</Text>
            <Progress
              percent={progress}
              showInfo={false}
              strokeColor="#6B4F4F"
            />
          </div>

          {isLetterHistoryLoading ? (
            <div style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              height: '100%',
              width: '100%',
            }}>
              <Spin
                indicator={<LoadingOutlined style={{ fontSize: 48, color: '#808080' }} spin />}
              />
            </div>
          ) : (
            <List
              dataSource={sortedLetters}
              renderItem={item => (
                <List.Item
                  onClick={() => handleItemClick(item)}
                  style={{
                    padding: "10px 0",
                    borderBottom: "1px solid #f0f0f0",
                    cursor: "pointer",
                    transition: "background-color 0.3s",
                    fontFamily: "HvDtrail-Brandon",
                  }}
                  hoverable
                >
                  <Text>Letter no. {item.numberByDate}</Text>
                  <Text type="secondary">{new Date(item.created).toLocaleDateString()}</Text>
                </List.Item>
              )}
            />
          )}
        </div>
      </Card>
    </div>
  );
}
