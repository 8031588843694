import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import accountRestService from "../../services/acc-rest.service";
import postboxRestService from "../../services/postbox-rest.service";
const calculateNextLetterInfo = (letters) => {
    if (!letters || letters.length === 0) {
      return { daysRemaining: 30, progress: 0 };
    }
  
    const mostRecentLetter = [...letters].sort((a, b) => 
      new Date(b.created) - new Date(a.created)
    )[0];
  
    const today = new Date();
    const currentMonth = today.getMonth();
    const currentYear = today.getFullYear();
    
    // Get the first day of next month
    const nextLetterDate = new Date(currentYear, currentMonth + 1, 1);
    
    const timeDiff = nextLetterDate - today;
    const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));
    
    // Calculate total days in current month
    const totalDays = new Date(currentYear, currentMonth + 1, 0).getDate();
    const progress = Math.min(100, Math.max(0, ((totalDays - daysRemaining) / totalDays) * 100));
  
    return { daysRemaining, progress };
};

const initialState = {
    isUserLoading: false,
    loggedInUser: null,
    currentSendLetter: null,
    letterHistory: [],
    isLetterHistoryLoading: false,
  nextLetterInfo: {
    daysRemaining: 30,
    progress: 0
  }
}

export const getLoggedInUser: any = createAsyncThunk(
    "app/getLoggedInUser",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await accountRestService.loadMe();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const sendLetter: any = createAsyncThunk(
    "app/sendLetter",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await postboxRestService.writeLetter(finalData);
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const loadLetterHistory: any = createAsyncThunk(
    "app/loadLetterHistory",
    async ({finalData, callbackSuccess, callbackError}: any, thunkAPI) => {
        try {
            const response = await postboxRestService.letterHistory();
            if (typeof callbackSuccess === 'function')
                callbackSuccess(response)
            return response;
        } catch (error) {
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();
            if (typeof callbackError === 'function')
                callbackError(message)
            return thunkAPI.rejectWithValue(message);
        }
    }
);

export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        setAppState: (state, action) => {
            console.log("Action called setAppState")
            console.log(state)
            console.log(action.payload)
            state = {...state, ...action.payload};
        },
        setNextLetterInfo: (state, action) => {
            state.nextLetterInfo = action.payload;
          }
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getLoggedInUser.pending, (state) => {
                state.isLoggeInUserLoading = true;
            })
            .addCase(getLoggedInUser.fulfilled, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.loggedInUser = action.payload;
            })
            .addCase(getLoggedInUser.rejected, (state, action) => {
                state.isLoggeInUserLoading = false;
                state.errorPaylod = action.payload;
            })
            .addCase(loadLetterHistory.pending, (state) => {
                state.isLetterHistoryLoading = true;
              })
              .addCase(loadLetterHistory.fulfilled, (state, action) => {
                state.isLetterHistoryLoading = false;
                state.letterHistory = action.payload;
        
                // 👉 Calculate next letter info right here
                state.nextLetterInfo = calculateNextLetterInfo(action.payload);
              })
              .addCase(loadLetterHistory.rejected, (state, action) => {
                state.isLetterHistoryLoading = false;
                state.errorPaylod = action.payload;
              })
            .addCase(sendLetter.pending, (state) => {
                state.isSendLetterLoading = true;
            })
            .addCase(sendLetter.fulfilled, (state, action) => {
                state.isSendLetterLoading = false;
                state.currentSendLetter = action.payload;
            })
            .addCase(sendLetter.rejected, (state, action) => {
                state.isSendLetterLoading = false;
                state.errorPaylod = action.payload;
            })
    },
});

export const {setAppState, setNextLetterInfo} = appSlice.actions;

export default appSlice.reducer;