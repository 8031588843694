import {
    Button, Card,
    Result,
} from "antd";
import {getAuthenticatedUser} from "../utils/authHelper";
import type {AMJ_User} from "../utils/authHelper";
import {Redirect} from "react-router-dom";
import {useAuth} from "react-oidc-context";


function AccessDeniedPage() {
    const auth = useAuth();
    const user: AMJ_User =  getAuthenticatedUser()
    const access_error = !user ? "Sorry, Authentication failed!" : !user.isAccountVerified() ? "Sorry, your account is not approved." : !user.isAdmin() ? "Sorry, you are not authorized to access this page." : null;

    if(auth.isAuthenticated && !user.isAccountVerified())
    {
        return  <Redirect to={"/profile"}/>
    }
    
    if(auth?.isAuthenticated && user?.isAccountVerified())
    {
        return  <Redirect to={"/"}/>
    }
    
    return (
    <Card>
        { access_error ?
            <Result
                status="403"
                title="403"
                subTitle={access_error}
            /> :
            <Result
                status="error"
                title="Technical Error"
                subTitle={"Sorry, technical issue occurred!"}
            />
        }
    </Card>
  );
}

export default AccessDeniedPage;
