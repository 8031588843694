import React from "react";
import { Card, Typography, Select, Input, Button, Row, Col } from "antd";
import { ArrowLeftOutlined, GlobalOutlined, DownOutlined ,EditFilled} from "@ant-design/icons";
import { Link } from 'react-router-dom';
import { categories, languages } from "../utils/language";
import addressInfo from "../assets/images/address-info.svg";
import addressHeader from "../assets/images/address-header.svg";
import letterwarning from "../assets/images/letter-warn.svg";
import letterwarnsign from "../assets/images/letter-warn-sign.svg";
import editIcon from "../assets/images/edit-icon.svg";
const { Title, Text } = Typography;
const { Option } = Select;

export default function LetterOptions({ data, onDataUpdate, onNext }) {
    const getCategoryOptions = () => {
        // Default to english if no language is selected
        
        if (!data.language || data.language === 'english') {
            return categories.english;
        } else if (data.language === 'urdu') {
            return categories.urdu;
        }
        // For other languages, fallback to English
        return categories.english;
    };

    return (
        <Row justify="center" align="middle" style={{ flex: 1 }}>
            <Col xs={24} sm={22} md={20} lg={18} xl={16}>
            
                <Card bordered={false} className="criclebox h-full" style={{ padding: "32px" }}>
                <Link to="/" style={{ marginRight: '16px' }}>
                            <ArrowLeftOutlined style={{ fontSize: '28px', color: '#B6B6B6', cursor: 'pointer' }} />
                        </Link>
                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '16px' }}>
                        
                        <Title level={3} style={{
                            fontFamily: "HvDtrail-Brandon",
                            fontSize: "30.74px",
                            fontWeight: 700,
                            lineHeight: "43.95px",
                            textAlign: "left",
                            marginBottom: 0,
                            color: "black"
                        }}>
                            Send a Letter
                        </Title>
                    </div>

                  

                    {/* Language Select */}
                    <Select
                        style={{
                            width: "100%",
                            marginBottom: "24px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                        }}
                        placeholder={<span style={{
                            fontFamily: "HvDtrail-Brandon",
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "15.73px",
                            color: "#494646",
                            opacity: 0.5
                        }}>
                            Choose Language
                        </span>}
                        dropdownStyle={{ borderRadius: "6px" }}
                        suffixIcon={<GlobalOutlined style={{ color: "#56B3B3", fontWeight: "bold" }} />}
                        value={data.language}
                        onChange={(value) => onDataUpdate({ language: value })}
                    >
                        {Object.entries(languages).map(([value, label]) => (
                            <Option style={{ fontFamily: "HvDtrail-Brandon" , fontSize: "18px"}} key={value} value={value}>
                                {label}
                            </Option>
                        ))}
                    </Select>

                    {/* Category Select */}
                    <Select
                        style={{
                            width: "100%",
                            marginBottom: "24px",
                            boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)"
                        }}
                        placeholder={<span style={{
                            fontFamily: "HvDtrail-Brandon",
                            fontSize: "18px",
                            fontWeight: 700,
                            lineHeight: "15.73px",
                            color: "#494646",
                            opacity: 0.5
                        }}>
                            Choose Category
                        </span>}
                        dropdownStyle={{ borderRadius: "6px" }}
                        suffixIcon={<DownOutlined style={{ color: "#56B3B3", fontWeight: "bold" }} />}
                        value={data.category}
                        onChange={(value) => onDataUpdate({ category: value })}
                    >
                        {Object.entries(getCategoryOptions()).map(([value, label]) => (
                            <Option key={value} value={value} style={{
                                fontFamily: data.language === 'urdu' ? 'Noto Nastaliq Urdu' : "HvDtrail-Brandon",
                                fontSize: data.language === 'urdu' ? '15px' : '17px',
                                padding: data.language === 'urdu' ? '8px 12px' : '4px 12px',
                                lineHeight: data.language === 'urdu' ? '2' : 'inherit',
                                textAlign: data.language === 'urdu' ? 'right' : 'left'
                            }}>
                              {label}
                            </Option>
                        ))}
                    </Select>
                    <img 
                            src={addressInfo} 
                            alt="Address Info" 
                            
                        />
                   
                    {/* Address Input */}

                    
                    <Card
                        bordered={false}
                        style={{
                            backgroundColor: "rgba(0, 0, 0, 0.03)",
                            borderRadius: "6px",
                            marginBottom: "24px",
                            boxShadow: "none"
                        }}
                    >
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", marginBottom: "12px" }}>
                            <img 
                                src={addressHeader} 
                                alt="Address Info" 
                            />
                            <img 
                                src={editIcon} 
                                alt="Edit Address" 
                                style={{
                                    width: "14px", 
                                    height: "14px",
                                    cursor: "pointer"
                                }}
                                onClick={() => {
                                    // Focus the textarea when edit icon is clicked
                                    document.getElementById('address-textarea').focus();
                                }} 
                            />
                        </div>
                        <Input.TextArea
                            id="address-textarea"
                            value={data.address}
                            onChange={(e) => onDataUpdate({ address: e.target.value })}
                            style={{
                                display: "block",
                                fontFamily: "HvDtrail-Brandon",
                                fontSize: "15px",
                                lineHeight: "1.6",
                                color: "#494646",
                                backgroundColor: "transparent",
                                border: "1px solid transparent",
                                resize: "none",
                                padding: "8px 8px 8px 0px",
                                transition: "border-color 0.3s ease",
                            }}
                            onFocus={(e) => {
                                e.target.style.borderColor = "#6B4F4F";
                                e.target.style.backgroundColor = "rgba(255, 255, 255, 0.5)";
                            }}
                            onBlur={(e) => {
                                e.target.style.borderColor = "transparent";
                                e.target.style.backgroundColor = "transparent";
                            }}
                            autoSize={{ minRows: 2, maxRows: 6 }}
                            placeholder="Enter your address..."
                        />
                    </Card>


                    <img 
                            src={letterwarnsign} 
                            alt="letter warn sign Info"
                            style={{paddingLeft: "10px "}}

                            
                        />
                        <br/>
                        <img 
                        src={letterwarning} 
                        alt="letter warn text Info" 
                        style={{padding: "10px"}}
                        
                    />

                    {/* Next Button */}
                    <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                        <Button
                            type="primary"
                            onClick={onNext}
                            disabled={!data.category || !data.language || !data.address}
                            style={{
                                marginBottom: "24px",
                                backgroundColor: "#755B48",
                                borderColor: "#755B48",
                                fontFamily: "'HvDTrial Brandon Grotesque', sans-serif",
                                fontSize: "32px",
                                fontWeight: 200,
                                lineHeight: "30.76px",
                                textAlign: "center",
                                height: "auto",
                                padding: "10px 0",
                                borderRadius: "20px",
                                width: "200px",
                                maxWidth: "80%",
                                color: "white",
                                fontFamily: "HvDtrail-Brandon"
                            }}
                        >
                            Next
                        </Button>
                    </div>
                </Card>
            </Col>
        </Row>
    );
}