export const categories = {
    english: {
      health_illness: "Health/Illness",
      studies_education: "Studies/Education",
      family_marital_issues: "Family/Marital Issues",
      spiritual_religious_matters: "Spiritual/Religious Matters",
      career_employment: "Career/Employment",
      financial_issues: "Financial Issues",
      marriage_proposals: "Marriage Proposals",
      children_parenting: "Children/Parenting",
      distress_challenges: "Distress/Challenges",
      religious_jamaat_work_responsibilities: "Religious and Jamaat Work/Responsibilities",
      dreams_interpretations: "Dreams/Interpretations",
      religious_devotions_personal_pledges: "Religious Devotions/Personal Pledges",
      special_occasions: "Special Occasions",
      immigration_legal_matters: "Immigration/Legal Matters",
      anxiety_mental_health: "Anxiety/Mental Health",
      relocation_moving: "Relocation/Moving",
      difficulties_faith_religious_practice: "Difficulties in Faith/Religious Practice",
      prayers_deceased_loved_ones: "Prayers for Deceased Loved Ones",
      gratitude_thanks: "Gratitude and Thanks",
      hajj_umrah_related: "Hajj and Umrah-Related",
      business_investment: "Business/Investment",
      other: "Other",
    },
    urdu: {
        advice_guidance: "مشورہ / راہنمائی (Mashwara / Rahnumai)",
        health_illness: "صحت / بیماری (Sehat / Bimari)",
        studies_education: "تعلیم (Taleem)",
        family_marital_issues: "گھریلو / عائلی مسائل (Gharelu / Aili Masail)",
        spiritual_religious_matters: "روحانی / مذہبی معاملات (Ruhani / Mazhabi Mamlaat)",
        career_employment: "ملازمت / روزگار (Mulazmat / Rozgar)",
        financial_issues: "مالی مسائل (Mali Masail)",
        marriage_proposals: "رشتے کے معاملات (Rishtey ke Mamlaat)",
        children_parenting: "بچے / تربیت (Bachchay / Tarbiyat)",
        distress_challenges: "پریشانیاں / مشکلات (Pareshaniyan / Challenges)",
        religious_jamaat_work_responsibilities: "جماعتی ذمہ داریاں / دینی فرائض (Jamaati Zimmadariyan / Deeni Faraiz)",
        dreams_interpretations: "خواب / تعبیر (Khwab / Tabir)",
        religious_devotions_personal_pledges: "اخلاص و وفا / عقیدت (Ikhlas o Wafa / Aqeedat)",
        special_occasions: "خاص مواقع (Khas Moqai)",
        immigration_legal_matters: "امیگریشن / قانونی معاملات (Immigration / Qanooni Mamlaat)",
        anxiety_mental_health: "ذہنی صحت / پریشانی (Zehni Sehat / Pareshani)",
        relocation_moving: "نقل مکانی (Naql Makani)",
        difficulties_faith_religious_practice: "مذہبی اور دینی مشکلات (Mazhabi aur Deeni Mushkilat)",
        prayers_deceased_loved_ones: "مرحومین کےلئے دعا مغفرت (Marhumeen ke liye Dua Maghfirat)",
        gratitude_thanks: "اظہار تشکر (Izhaar Tashakkur)",
        hajj_umrah_related: "حج اور عمرہ سے متعلق (Hajj aur Umrah se Mutaliq)",
        business_investment: "کاروبار / سرمایہ کاری (Karoorbar / Sarmayakari)",
        other: "متفرق (Mutafarriq)",
      }
    };
  
  export const languages = {
    english: "English",
    urdu: "Urdu",
    deutsch: "Deutsch",
    roman_urdu: "Roman Urdu",
  };